// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

window.Rails = Rails;

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import '../src/datatables_init.js';
import '../src/signature.js';
import '../src/users.js';
import '../src/products.js';
import '../src/custom.js';
import '../src/map_revision.js';
import '../src/map_editor.js';
import '../src/map_show_static_canvas.js';
import '../src/nested_fields.js';
import '../src/template_inspection_reports.js';
import '../src/companies.js';
import '../src/proposals.js';
import '../src/sites.js';
import '../src/lot_stabilization_log_entries.js';
import '../src/swpp_image_attachments.js';
import '../src/maps.js';
import '../src/bulk_download.js';
import '../src/dashboards.js';
import '../src/reports.js';
import '../src/work_orders.js';
import '../src/rain_events.js';
import '../src/direct_uploads.js';
import '../src/user_notifications.js';
import '../src/grading_logs.js';
import '../src/legend_items.js';
import '../src/features.js';
import '../src/data-confirm-modal.js';
import '../src/ckeditor_init.js';
import '../src/swpp_corrective_actions.js';
import '../src/swpp_inspections.js';
import '../src/mapbox.js';
import '../src/datepicker.js';
